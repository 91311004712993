<template>
  <div>
    <div class="participant-info" style="height: auto">
      <!-- 標題 -->
      <div class="single-page-tag mb-3">
        <p class="sp-start sp-text fw-bolder">顧客詳細資訊</p>
      </div>
      <div>
        <!-- 基本資訊 -->
        <div class="top-part">
          <!-- 上半部 -->
          <div class="row">
            <!-- 圖片 -->
            <div class="col-lg-1 col-sm-2 col-6 p-0 pe-2">
              <Image
                :url="participantData.profilePicture"
                v-if="participantData.profilePicture"
                :alt="participantData.profilePicture"
                style="width: 100%; min-width: 100px"
              ></Image>
              <img
                v-else
                src="@/assets/other-images/nobody.png"
                style="width: 100%; min-width: 100px"
              />
            </div>
            <!-- 資訊 -->
            <div
              class="col-lg-11 col-sm-10 col-6 row p-0"
              style="align-items: flex-end"
            >
              <div class="col-sm-8 col-12 row align-items-center">
                <div
                  class="col-sm-4 d-inline-block"
                  style="min-width: fit-content"
                >
                  <p class="fw-bolder tw-text-size20 my-2">
                    顧客姓名： {{ participantData.name }}
                  </p>
                  <p class="fw-bolder tw-text-size20 my-2">
                    顧客暱稱：
                    <template v-if="participantData.nickName">
                      {{ participantData.nickName }}
                    </template>
                    <template v-else> 無設定暱稱 </template>
                  </p>
                  <p class="fw-bolder tw-text-size20 my-2">
                    顧客群組：
                    <template v-if="participantData.customerGroup">
                      {{ participantData.customerGroup.name }}
                    </template>
                    <template v-else>無顧客群組</template>
                  </p>
                  <p class="fw-bolder tw-text-size20 my-2">
                    <img
                      class="icon me-1"
                      src="@/assets/icon/crown1.png"
                      alt=""
                    />
                    VIP
                    <template v-if="participantData.vipData">
                      {{ participantData.vipData.level }}：{{
                        participantData.vipData.name
                      }}
                    </template>
                    <template v-else>：無VIP等級</template>
                  </p>
                </div>
                <div
                  class="col-sm-8 d-inline-block"
                  style="min-width: fit-content"
                >
                  <p
                    class="badge bg-primary tw-text-size18 mb-2"
                    v-if="participantData.userFacebookName"
                  >
                    FB：{{ participantData.userFacebookName }}
                  </p>
                  <br />
                  <p
                    class="badge bg-success tw-text-size18"
                    v-if="participantData.userLineName"
                  >
                    LINE：{{ participantData.userLineName }}
                  </p>
                </div>
              </div>
              <div class="col-sm-4 col-12 text-end d-inline-block">
                <router-link
                  class="tw-btn tw-btn-secondary h-100"
                  :to="`/seller/store/${storeId}/participant`"
                  >返回顧客管理</router-link
                >
              </div>
            </div>
          </div>
          <!-- 下半部換頁 nav -->
          <ul class="navigation">
            <li
              :class="{
                active: currentPage === 'P_MerchOrder',
                'border-bottom-0': currentPage === 'P_MerchOrder',
              }"
            >
              <router-link
                :to="`/seller/store/${storeId}/participant/${participantId}/merchOrder`"
                >訂單管理</router-link
              >
            </li>
            <li
              :class="{
                active: currentPage === 'P_Bill',
                'border-bottom-0': currentPage === 'P_Bill',
              }"
            >
              <router-link
                :to="`/seller/store/${storeId}/participant/${participantId}/bill`"
                >帳單管理</router-link
              >
            </li>
            <li
              :class="{
                active: currentPage === 'P_ShoppingGold',
                'border-bottom-0': currentPage === 'P_ShoppingGold',
              }"
            >
              <router-link
                :to="`/seller/store/${storeId}/participant/${participantId}/shoppingGold`"
                >購物金</router-link
              >
            </li>
            <li
              :class="{
                active: currentPage === 'P_Receiving',
                'border-bottom-0': currentPage === 'P_Receiving',
              }"
            >
              <router-link
                :to="`/seller/store/${storeId}/participant/${participantId}/receiving`"
                >收件資訊</router-link
              >
            </li>
          </ul>
          <!-- 分頁內容 -->
          <div
            class="tw-container merchandise-info-content p-0 pt-4 m-0 mb-5 border-start border-end border-bottom border-2"
          >
            <router-view></router-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      serverToken: '',
      storeId: 0,
      participantId: null,
      currentPage: '',
      participantData: {
        name: '',
        nickName: '',
        vipData: '',
        userFacebookName: '',
        userLineName: '',
        profilePicture: '',
      }
    }
  },
  created() {
    this.initialization()
    this.getParticipantList()
  },
  watch: {
    $route(to) {
      this.currentPage = to.name
    },
  },
  methods: {
    // 初始化
    initialization() {
      this.serverToken = this.$methods.getCookie('serverToken')
      this.storeId = this.$route.params.storeId
      this.participantId = this.$route.params.participantId
      this.currentPage = this.$route.name
    },
    // 取得顧客資訊
    getParticipantList() {
      const vm = this
      const getParticipantList = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 13,
          ids: [this.participantId],
          methods: '{getUser{getUserLineLink,getUserFacebookLink},getStoreVip,getCustomerGroup}'
        }
      ]
      this.participantList = []
      $.ajax({
        type: 'POST',
        async: true,
        url: getParticipantList,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            console.log("資料抓取成功")
            const participantList = res.data[0].objects[0]
            console.log(participantList)
            vm.participantData = {
              name: participantList.user.name,
              nickName: participantList.nickName,
              vipData: participantList.storeVip,
              customerGroup: participantList.customerGroup,
              userFacebookName:  participantList.user.userFacebookLink.name ,
              userLineName: participantList.user.userLineLink.name,
              profilePicture: null
            }
            if (participantList.user.userLineLink && participantList.user.userLineLink.profilePicture) vm.participantData.profilePicture = participantList.user.userLineLink.profilePicture
            else if (participantList.user.userFacebookLink && participantList.user.userFacebookLink.profilePicture) vm.participantData.profilePicture = participantList.user.userFacebookLink.profilePicture
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
          this.dataTabelLoading = false
        },
      })
    },

  },
}
</script>